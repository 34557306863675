* {
    margin: 0;
    font-family: 'Josefin Sans', sans-serif;
}

.home__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('/background.png');
    background-repeat: repeat;
    min-width: 100%;
    max-height: 100%;
}

.arrow_dropup__container {
    position: fixed;
    top: 85%;
    right: 2%;
    z-index: 2000;
    .MuiSvgIcon-root {
        color: white;
        transform: scale(1.75);
    }
}

.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 3000;
    top: 0;
    left: 0;
    background: url('/background.png') repeat;
    overflow: hidden;
    transition: 1s ease-in-out;
}

.overlay-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-size: 25px;
    h1 {
        font-weight: 300;
        font-size: 1em;
        font-family: 'Encode Sans Semi Expanded', sans-serif;
        text-transform: capitalize;
    }
    a {
        padding: 8px;
        text-decoration: none;
        font-size: 36px;
        color: #818181;
        display: block;
        transition: 0.3s;
        &:hover, &:focus {
            color: #f1f1f1;
        }
    }
    .closebtn__container {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        max-height: 2em;
        .closebtn {
            padding: 5vw;
            display: flex;
            justify-content: center;
            z-index: 4000;
            .MuiSvgIcon-root {
                font-size: 1em;
            }
        }
    }
}

iframe {
    width: 720px;
    height: 480px;
    max-width: 100vw;
}

.video__container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100vw;
    height: 46.59vw;
    video {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        z-index: 1;
        &::-webkit-media-controls {
            display:none !important;
        }
    }
}

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        z-index: 1000;
        min-width: 100%;
        min-height: 100%;
        width: auto; 
        height: auto;
        background: url('/header-video-layer-2.png') no-repeat center center;
        background-size: 70% 70%;
    }
}

@media screen and (max-width: 414px) {
    .video-overlay {
        &:after {
            background-size: 100% auto;
        }
    }
}

.text-copy {
    position: absolute;
    top: calc(1vw + 5px);
    right: calc(1vw + 5px);
    z-index: 2;
    text-shadow: 1px 1px 1px #000;
    display: flex;
    flex-direction: row;
    a {
       color: #E6B34D;
       margin-left: 10px;
    }
}

@keyframes arrows {
    0% { top: 75%; }
    50% { top: 76%; }
    100% { top: 75%; }
}

.arrow__container {
    display: flex;
    flex-direction: row;
    text-align: center;
    z-index: 2000;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
}

.arrow-first {
    position: absolute;
    top: 75%;
    animation-name: arrows;
}

.arrow-second {
    position: absolute;
    top: 25%;
    animation-name: arrow-second;
}

@keyframes arrow-second {
    0% { top: 20% }
    25% { top: 23% }
    50% { top: 26% }
    75% { top: 23% }
    100% { top: 20% }
}

.arrow__area {
    width: 8vw;
    height: 8vw;
    position: relative;
    display: flex;
    justify-content: center;
}

.padding-top-60px {
    padding-top: 60px;
}

.padding-top-40px {
    padding-top: 40px;
}

.padding-top-30px {
    padding-top: 30px;
}

.padding-top-20px {
    padding-top: 20px;
}

.next__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    z-index: 1000;
    color: white;
    background: inherit;
    h1 {
        font-size: 35px;
        color: #E6B34D;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 700;
    }
}

.grid__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1920px;
    justify-content: center;
    margin-left: 4vw;
    margin-right: 4vw;
}

.grid__item {
    display: flex;
    justify-content: center;
    position: relative;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 15px;
    pointer-events: auto;
    .thumb__title {
        opacity: 0;
        color: white;
        font-family: 'Lato', sans-serif;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1000;
        transition: opacity 1s ease-in-out;
        pointer-events: none;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        transition: transform .2s;
        z-index: 1001;
        pointer-events: none;
    }
    span {
        pointer-events: none;
    }
    &:hover, &:focus {
        .thumb__title {
            opacity: 1;
            transition: opacity 1s ease-in-out;
        }
        img {
            filter: blur(2px) opacity(50%);
            -webkit-filter: blur(2px) opacity(50%);
            transform: scale(1.05);
            transition: transform 1s ease-in-out;
        }
    }
}

@media screen and (max-width: 1366px) {
    .grid__item {
        min-width: 240px;
        max-width: 240px;
        min-height: 135px;
        max-height: 135px;
    }
}

@media screen and (min-width: 1367px) {
    .grid__item {
        min-width: 300px;
        max-width: 21vw;
        min-height: 168.75px;
        max-height: 11.8125vw;
    }
}

.opacity-effect {
    animation-name: opacity-effect;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes opacity-effect {
    from {opacity: 0.5;}
    to {opacity: 1;}
}

.pagination__nav {
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        list-style: none;
        li {
            width: 35px;
            height: 35px;
            border-radius: 17.5px;
            background: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
                text-decoration: none;
                color: white;
                font-weight: bold;
            }
            &.current {
                background: #CFA144;
                -webkit-box-shadow: inset 0px 0px 3px 0px #000000; 
                box-shadow: inset 0px 0px 3px 0px #000000;
                a {
                    color: black;
                }
            }
        }
    }
}

.next__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 10vw;
    padding-right: 10vw;
    .next__content__column {
        display: flex;
        align-items: center;
        padding: 10px;
        p {
            text-align: justify;
            line-height: 1.6;
        }
    }
    .left {
        flex-grow: 1;
        flex-basis: 33%;
    }
    .right {
        display: flex;
        justify-content: center;
        flex-grow: 2;
        flex-basis: 67%;
    }
}

@media screen and (max-width: 1023px) {
    .next__content {
        flex-direction: column; 
    }
}

@media screen and (min-width: 1024px) {
    .next__content__column {
        flex-grow: 1;
        flex-basis: 50%;
    }
}

.has-border {
    border: .5px solid pink;
}

div[data-qa-node*='Arrow'] {
    background: none;
    border: none;
}

div[data-qa-node='SliderWrapper'] {
    max-width: 98vw;
    width: auto;
}

footer {
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    padding-top: 80px;
    padding-bottom: 80px;
    height: 40px;
    .contacts {
        display: flex;
        flex-direction: row;
        align-items: center;
        div {
            display: flex;
            align-items: center;
            padding: 6px;
        }
        img {
            height: 15px;
            margin-left: 10px;
        }
        a {
            text-decoration: none;
            color: white;
        }
        span {
            margin-left: 5px;
            font-size: 14px;
        }
    }
    .copyright {
        font-size: 12px;
    }
}

@media screen and (max-width: 767px) {
    footer {
        .contacts {
            flex-direction: column;
        }
    }
}


